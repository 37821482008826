<template>
  <div class="edit-div-ahover">
    <div class="fontSize20gay text-left">看板设置</div>
    <div style="border-bottom: 1px solid #EBEBEB;border-top: 1px solid #EBEBEB;margin-top: 16px;padding: 36px 0 90px 0">
      <div style="width: 950px;">
        <el-form :model="pageForm" :rules="pageFormRules" ref="formRef" label-width="140px">
          <el-form-item label="预警弹窗轮播频率" prop="frequency">
            <el-input v-model="pageForm.frequency"
                      style="width: 150px"
                      class="room-input-tl"
                      placeholder="请输入">
              <template #append>s</template>
            </el-input>
          </el-form-item>
          <el-form-item label="展示数据范围" prop="range">
            <el-radio-group v-model="pageForm.range" size="mini">
              <el-radio label="7" border>近7天</el-radio>
              <el-radio label="15" border>近15天</el-radio>
              <el-radio label="30" border>近30天</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="默认监控分辨率" prop="resolutionRatio">
<!--
            <el-input v-model="pageForm.resolutionRatio"
                      style="width: 150px"
                      class="room-input-tl"
                      placeholder="请输入"></el-input>
-->
            <el-select v-model="pageForm.resolutionRatio" placeholder="请选择">
              <el-option label="标清" value="480p"/>
              <el-option label="高清" value="720p"/>
            </el-select>
          </el-form-item>
          <el-form-item label="默认存储时长" prop="saveDay">
            <el-input v-model="pageForm.saveDay"
                      style="width: 150px"
                      class="room-input-tl"
                      placeholder="请输入">
              <template #append>天</template>
            </el-input>
          </el-form-item>

          <div class="event-box">
            <div class="title">事件设置</div>
            <div v-for="(item, index) in pageForm.warningEventTypeList"
                 :key="item.id"
                 class="event-item flex align-center">
              <div>{{ item.name }}：</div>
              <div class="flex align-center">
                <span style="margin-right: 10px;">识别</span>
                <el-switch v-model="item.identify" active-color="#13ce66"></el-switch>
              </div>
              <div>
                <span style="margin-right: 10px;">展示</span>
                <el-switch v-model="item.show" active-color="#13ce66"></el-switch>
              </div>
            </div>
          </div>

          <div class="event-box">
            <div class="title marginBottom12">预置式设置</div>
            <el-row v-for="(iter, index) in warningProcessing" :key="index" class="marginBottom12">
              <el-col :span="2">
                <span v-if="iter.type === 0">{{ `选项${index+1}` }}</span>
                <span v-else>其他选项</span>
              </el-col>
              <el-col :span="12">
                <el-input v-if="iter.type === 0" type="textarea" :rows="2" placeholder="请输入内容" v-model="iter.content"> </el-input>
              </el-col>
              <el-col :span="2" class="flex" style="padding-left: 10px;">
                <el-button icon="Delete" @click="removeOption(index)"/>
                <el-button icon="Select" @click="addOption(index)"/>
              </el-col>
            </el-row>
            <button class="subBtn marginTop12px" type="button" @click="addOptions(1)">新增固定</button>
            <button v-show="hasOther()" class="subBtn marginTop12px marginLeft10" type="button" @click="addOptions(2)">新增其他</button>
          </div>

        </el-form>
      </div>
    </div>
    <div class="marginTop15px">
      <button class="subBtn" @click="subData">保存</button>
      <button class="qxBtn" @click="qxBtn">取消</button>
    </div>
  </div>
</template>
<script>
import editor from "@/components/editor.vue";
import {
  deleteWarningEventOpinion,
  queryDataViewSetting,
  setDataViewSetting,
  updateWarningEventOpinion,
  warningEventOpinion,
  warningEventOpinionAll
} from "@/api/monitor";

const deepClone = obj => {
  if (obj === null) return null;
  if (typeof obj !== 'object') return obj;
  let clone = Array.isArray(obj) ? [] : {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      clone[key] = deepClone(obj[key]);
    }
  }
  return clone;
}

export default {
  components: {
    editor
  },
  name: "index",
  props: ['Row'],
  data() {
    return {
      pageForm: {
        frequency: '',
        range: '',
        resolutionRatio: '',
        saveDay: '',
        warningEventTypeList: [],
      },
      pageFormRules: {
        frequency: [ { required: true, message: '请填写预警弹窗轮播频率', trigger: 'blur' } ],
        range: [ { required: true, message: '请选择展示数据范围', trigger: 'blur' } ],
        resolutionRatio: [ { required: true, message: '请填写默认监控分辨率', trigger: 'blur' } ],
        saveDay: [ { required: true, message: '请填写默认存储时长', trigger: 'blur' } ],
      },
      warningProcessing: [
        // { content: '', type: '', }
      ]
    }
  },
  mounted() {
    this.getInfo()
    this.getWarningEventOpinionAll()
  },
  methods: {
    hasOther(){
      return !this.warningProcessing.find(iter => iter.type === 1)
    },
    addOptions(type){
      if (type === 1) {
        this.warningProcessing.push({ content: '', type: 0, })
        return
      }
      this.warningProcessing.push({ content: '其他', type: 1, })
    },
    removeOption(index){
      const item = this.warningProcessing[index]
      // 接口删除
      if (Reflect.has(item, 'id')) {
        this.$confirm('确认删除当前选项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info'
        }).then(() => {
          deleteWarningEventOpinion(item.id).then(res => {
            if (res.success) {
              this.$message.success('提交成功!')
              this.getWarningEventOpinionAll()
            }
          })
        })
        return
      }
      // 删除
      this.warningProcessing.splice(index, 1)
    },
    addOption(index){
      const item = this.warningProcessing[index]
      // 编辑
      if (Reflect.has(item, 'id')) {
        updateWarningEventOpinion(item).then(res => {
          if (res.success) {
            this.$message.success('提交成功!')
            this.getWarningEventOpinionAll()
          } else {
            this.$message.error(res.message)
          }
        })
        return
      }
      // 新增
      warningEventOpinion(item).then(res => {
        if (res.success) {
          this.$message.success('提交成功!')
          this.getWarningEventOpinionAll()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    /**
     *
     */
    getInfo() {
      queryDataViewSetting().then(res => {
        this.pageForm = res.data
        this.pageForm.warningEventTypeList.map(iter => {
          iter.identify = !!iter.identify
          iter.show = !!iter.show
        })
      })
    },
    getWarningEventOpinionAll(){
      warningEventOpinionAll().then(res => {
        if (res.success) {
          this.warningProcessing = []
          console.log(res.data);
          Array.isArray(res.data) && res.data.forEach(iter => {
            const { content, type, id } = iter
            this.warningProcessing.push({ content, type, id  })
          })
        }
      })
    },
    /**
     * 提交
     */
    subData() {
      /**
       * 提交
       */
      const submitHandler = () => {
        const params = deepClone(this.pageForm)
        params.warningEventTypeList.map(iter => {
          iter.identify = iter.identify ? 1 : 0
          iter.show = iter.show ? 1 : 0
        })
        setDataViewSetting(params).then(res => {
          if(res.success){
            this.$root.ElMessage.success(res.message)
            setTimeout(() => {
              this.getInfo()
            }, 500)
          } else{
            this.$root.ElMessage.error(res.message);
          }
        })
      }

      this.$refs.formRef.validate((valid, error) => {
        if (valid) {
          submitHandler()
        } else {
          const key = Object.keys(error)[0]
          const msg = error[key][0].message
          this.$root.ElMessage.error(msg)
        }
      })
    },
    /**
     * 取消
     */
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
  }
}
</script>

<style lang="scss" scoped>
.event-box {
  text-align: left;

  .title {
    font-weight: bold;
  }

  .event-item {
    display: flex;
    padding: 10px 0;

    & > div {
      padding-left: 20px;
    }

  }
}

</style>